<template>
  <div class="stepper" :class="{'d-inline-block': inline}">
    <b-input-group>
      <b-btn :size="size" @click="add(-1)" slot="prepend" :disabled="count <= min">
        <fa icon="minus"/>
      </b-btn>
      <b-input placeholder="数量" type="number" ref="input" :min="min" :max="max"
               @change="validate" v-model="count" :size="size" number/>
      <b-btn @click="add(1)" :disabled="count >= max" slot="append" :size="size">
        <fa icon="plus"/>
      </b-btn>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'stepper',
  data() {
    return {
      count: this.value || 1
    }
  },
  watch: {
    count(val) {
      if (val) {
        val = Math.abs(val)
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.setCount(this.value)
  },
  methods: {
    setCount(count) {
      this.count = count || 1
    },
    validate() {
      this.count = Math.min(this.$refs.input.max, this.count)
      this.count = Math.max(this.$refs.input.min, this.count)
    },
    add(count) {
      this.count += count
      this.count = Math.min(this.max, Math.max(1, this.count))
    }
  },
  props: {
    value: {
      default: 1
    },
    min: {
      type: String,
      default: '1'
    },
    max: {
      type: String,
      default: '999'
    },
    size: {
      type: String,
      default: 'md'
    },
    inline: Boolean
  }
}
</script>

<style scoped lang="scss">
  .stepper {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    input {
      text-align: center;
    }
  }
</style>
