<template>
  <div class="address-splitter">
    <textarea rows="3" v-autosize v-model.trim="rawInfo" class="form-control" @input="onInput" :disabled="!areas.length"
              placeholder="在此处粘贴或填写完整的收件信息（包括姓名、手机号、地址），系统会进行自动识别，如果识别有问题，请手动修改"/>
  </div>
</template>

<script>
import { chain, keys } from 'lodash'

export default {
  name: 'addressSplitter',
  created() {
    this.fetchAreas()
  },
  data() {
    return {
      rawInfo: '',
      areas: []
    }
  },
  methods: {
    onInput() {
      if (!this.rawInfo) {
        this.$emit('change', {
          consignee: '',
          phone: '',
          address: ''
        })
        return
      }

      let string = this.rawInfo
        .replace(/收貨人|收貨手機|收貨地址|收货人|收件人|手机号|联系电话|详细地址|邮政编码|邮编|所在地区|收货地址|收件地址|地址/gi, ' ')
        .replace(/[,，。;；…?:：？！'"|\s]+/g, ' ')
        .trim()

      const provinces = chain(this.areas).map(keys).flatten().value()
      const addressBegin = chain(provinces).map(province => {
        return {
          province,
          index: string.indexOf(province.slice(0, 2))
        }
      }).filter(i => i.index > -1).orderBy('index').get(0).value()

      if (!addressBegin) {
        this.$emit('change', {
          consignee: '',
          phone: '',
          address: ''
        })
        return
      }

      const province = addressBegin.province
      let city = ''
      let district = ''

      let address = string.slice(Math.max(addressBegin.index, 0)).replace(/\d{6,}.+$/, '').trim()
      string = string.split(address).join('')

      const phone = (/[\d-]{8,}/.exec(string) || [''])[0]
      string = string.split(phone).join('')

      const consignee = string.replace(/\d{6,}/, '').trim()

      if (province) {
        address = address = this.trimStart(address, province)
        // console.log('after province', address)

        if (/北京|上海|天津|重庆/.test(province)) {
          city = province
        }

        if (!city) {
          const cities = chain(this.areas).find(i => i[province]).get(province).map(keys).flatten().value() || []
          for (const item of cities) {
            if (this.startsWith(address, item) || this.startsWith(address, this.replaceCity(item, true))) {
              city = item
              break
            }
          }
        }

        address = address = this.trimStart(address, city)
        // console.log('after city', address)

        const districts = chain(this.areas).find(i => i[province]).get(province).find(i => i[city]).get(city).value() || []
        for (const item of districts) {
          if (this.startsWith(address, item) || this.startsWith(address, this.replaceDistrict(item, true))) {
            district = item
            break
          }
        }

        address = this.trimStart(address, district)
        address = address.replace(new RegExp('^' + province + '\\s?' + city + '\\s?' + district), '')
        address = address.split('|').join(' ')
        // console.log('after district', address)
      }

      this.$emit('change', {
        consignee,
        phone: phone.slice(0, 11),
        address: [province, city, district, address].join('|')
      })
    },
    replaceCity(str, reverse) {
      const pairs = [
        // 吉林
        ['延边朝鲜族自治州', '延边州'],
        // 湖北
        ['恩施土家族苗族自治州', '恩施州'],
        ['湘西土家族苗族自治州', '湘西州'],
        // 湖南
        ['临夏回族自治州', '临夏州'],
        ['甘南藏族自治州', '甘南州'],
        // 四川
        ['甘孜藏族自治州', '甘孜州'],
        ['凉山彝族自治州', '凉山州'],
        ['阿坝藏族羌族自治州', '阿坝州'],
        // 贵州
        ['黔东南苗族侗族自治州', '黔东南州'],
        ['黔南布依族苗族自治州', '黔南州'],
        // ['黔西南布依族苗族自治州', '黔西南州'],
        // 内蒙
        ['昌吉回族自治州', '昌吉州'],
        ['伊犁哈萨克自治州', '伊犁州'],
        ['博尔塔拉蒙古自治州', '博尔塔拉州'],
        ['巴音郭楞蒙古自治州', '巴音郭楞州'],
        // 青海
        ['黄南藏族自治州', '黄南州'],
        ['海北藏族自治州', '海北州'],
        ['海南藏族自治州', '海南州'],
        ['果洛藏族自治州', '果洛州'],
        ['玉树藏族自治州', '玉树州'],
        ['海西蒙古族藏族自治州', '海西州'],
        // 云南
        ['迪庆藏族自治州', '迪庆州'],
        ['楚雄彝族自治州', '楚雄州'],
        ['大理白族自治州', '大理州'],
        ['怒江傈僳族自治州', '怒江州'],
        ['西双版纳傣族自治州', '西双版纳州'],
        ['文山壮族苗族自治州', '文山州'],
        ['红河哈尼族彝族自治州', '红河州'],
        ['德宏傣族景颇族自治州', '德宏州']
      ]
      str = str || ''
      for (const pair of pairs) {
        if (reverse) {
          str = str.replace(pair[1], pair[0])
          continue
        }
        str = str.replace(pair[0], pair[1])
      }
      return str
    },
    replaceDistrict(str, reverse) {
      const pairs = [
        ['龙华区', '龙华新区']
      ]
      str = str || ''
      for (const pair of pairs) {
        if (reverse) {
          str = str.replace(pair[1], pair[0])
          continue
        }
        str = str.replace(pair[0], pair[1])
      }
      return str
    },

    startsWith(target, str) {
      return target.startsWith(str.slice(0, 2))
    },
    trimStart(target, str) {
      if (!str) {
        return target.trim()
      }
      return target.trim().replace(new RegExp('^' + str.split('').join('?') + '?'), '').trim()
    },

    fetchAreas() {
      return this.$req.get('/buckets/weixinshu:areas').then(results => {
        this.areas = results
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
